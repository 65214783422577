<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part VI: Review Info</h2>
        <v-form class="mb-2">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-subtitle-1 font-weight-medium mb-0">Personal Info</p>
                    <display-label label="First Name" :text="object.first_name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Last Name" :text="object.last_name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Alias Name" :text="object.alias_name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="NRIC" :text="object.nric"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Email" :text="object.email"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Gender" :text="object.gender.text"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Race" :text="object.race.text"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Date of Birth" :text="object.dob"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Mobile Number" :text="object.mobile_number"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Citizenship" :text="object.citizenship.text"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.dopr">
                    <display-label label="Date of PR" :text="object.dopr"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Shirt Size" :text="object.shirt_size.text"></display-label>
                </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-subtitle-1 font-weight-medium mb-0">Address Info</p>
                    <display-label label="District" :text="object.district.text"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Block Number" :text="object.block_number"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Street Name" :text="object.street_name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Unit No." :text="object.unit_number"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.dopr">
                    <display-label label="Building Name" :text="object.building_name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Postal Code" :text="object.postal_code"></display-label>
                </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-subtitle-1 font-weight-medium mb-0">Bank Info</p>
                    <display-label label="Bank" :text="object.bank.text"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <display-label label="Account Number" :text="object.account_number"></display-label>
                </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-subtitle-1 font-weight-medium mb-0">Terms & Conditions</p>
                    <display-label label="" text="I have read and agreed to the terms and conditions"></display-label>
                </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-subtitle-1 font-weight-medium mb-0">Data Protection Notice</p>
                    <display-label label="" text="I acknowledge that I have read and understood the PDPA Notice, and consent to the collection, use and disclosure of my personal data by Elitez Group of Companies for the purposes set out in the Notice."></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                    <v-btn rounded large class="elevation-0" :ripple="false" @click.prevent.stop="previous">Previous</v-btn>
                    <v-btn rounded large color="primary" class="elevation-0" :ripple="false" :loading="loading" @click.prevent.stop="save">Submit</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>
import { copyObject } from '@/utils/helper'
import { encodeBase64 } from '@/utils/encodes'
import DisplayLabel from '../../shared/DisplayLabel.vue';

export default {
    name: 'RegistrationReviewForm',
    components: {
        displayLabel: DisplayLabel,
    },
    data() {
        return {
            loading: false,
            object: {
                race: {},
                gender: {},
                citizenship: {},
                shirt_size: {},
                district: {},
                bank: {},
            }
        }
    },
    methods: {
        previous: function() {
            this.$emit('previous')
        },
        save: function() {
            this.loading = true
            const excludeFields = ['nric', 'gender', 'race', 'citizenship', 'shirt_size', 'district', 'bank', 'account_number']
            var object = this.cleanObject(copyObject(this.object, excludeFields))

            this.$store.dispatch('mobile/account/createAccount', object).then(() => {
                this.$emit('next')
                this.loading = false
            }).catch((errors) => {
                this.errors = errors.data.errors
                this.loading = false
            })
        },
        updateObject: function(item) {
            this.object = item
        },
        cleanObject: function(item){
            item.nric = encodeBase64(this.object.nric)
            item.account_number = encodeBase64(this.object.account_number)
            item.race = this.object.race.value
            item.gender = this.object.gender.value
            item.citizenship = this.object.citizenship.value
            item.shirt_size = this.object.shirt_size.value
            item.district = this.object.district.value
            item.bank = this.object.bank.value
            return item
        }
    }
}

</script>


<style scoped>

/deep/ .v-btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

</style>