<template>
    <div>
        <v-layout align-center justify-center>
            <v-flex xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card class="pa-4 px-2 mx-auto rounded-lg elevation-0">
                    <v-card-text>
                        <p class="text-h5 font-weight-bold text--primary text-center">Thank You</p>
                        <p class="text-subtitle-2 text--primary text-center">We have received your application. Please contact our staff to procedure the process.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block rounded x-large color="primary" class="elevation-0" :ripple="false" @click.prevent.stop="next">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>


<script>

export default {
    name: 'RegistrationCompletedForm',
    methods: {
        next: function() {
            this.$emit('next')
        }
    }
}

</script>