<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part II: Address Info</h2>
        <v-form lazy-validation ref="form" class="mb-2" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete dense clearable label="District*" :items="districts" :rules="rules.district" :error-messages="errors.district" v-model="object.district"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field dense clearable label="Block No." :error-messages="errors.block_number" v-model="object.block_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Street Name*" :rules="rules.street_name" :error-messages="errors.street_name" v-model="object.street_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Unit No." :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Building Name/Residence Name" :error-messages="errors.building_name" v-model="object.building_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Postal Code*" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.postal_code"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">* Required field</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                    <v-btn rounded class="elevation-0" :ripple="false" @click.prevent.stop="previous">Previous</v-btn>
                    <v-btn rounded class="elevation-0" :ripple="false" color="primary" @click.prevent.stop="next">Next</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
    name: 'RegistrationAddressForm',
    props: {
        districts: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            object: {
                district: '',
                block_number: '',
                street_name: '',
                unit_number: '',
                building_name: '',
                postal_code: '',
            },
            rules: {
                district: [
                    (value) => !!value || 'District is required'
                ],
                street_name: [
                    (value) => !!value || 'Street name is required'
                ],
                postal_code: [
                    (value) => {
                        if(!value) {
                            return 'Postal code is required'
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 6 digits'
                        }

                        return true
                    }
                ],
            },
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        reset: function() {
            this.errors = {}
            this.object = { district: '', block_number: '', street_name: '', unit_number: '', building_name: '', postal_code: '' }
            this.form.reset()
            this.form.resetValidation()
        },
        previous: function() {
            this.$emit('previous')
        },
        next: function() {
            if(this.form.validate()){
                var object = copyObject(this.object)
                var district = this.districts.filter((i) => i.value === object.district)
                object.district = district.length > 0?{'text': district[0].text, 'value': district[0].value }:{'text': 'N.A.', 'value': object.district }
				this.$emit('next', object)
			}
        }
    }
}

</script>


<style scoped>

/deep/ .v-btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

</style>