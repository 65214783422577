<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part IV: Terms & Conditions</h2>
        <v-form lazy-validation ref="form" class="mb-2" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-subtitle-2 font-weight-regular">
                    <p>Last Updated: 22 March 2022</p>
                    <p class="red--text">PLEASE NOTE: THIS IS NOT AN EMPLOYMENT CONTRACT BETWEEN ELITEZ (FMCG) PTE LTD AND THE APPLICANT. ELITEZ (FMCG) ACT AS A PLATFORM AND FACILITATE JOBS SHIFTS ALLOCATION AND EARN A FEE FOR ITS SERVICES THROUGH THE USE OF THE ONLINE PLATFORM.</p>
                    <p class="text-subtitle-1 font-weight-medium">CONTRACT FOR SERVICE AGREEMENT</p>
                    <p>These Terms and Conditions of Agreement are between ELITEZ (FMCG) PTE LTD and the below-mentioned personnel, hereinafter known as "Sub-Contractor".</p>
                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">Service Engagement</p>
                    <p>1. The Sub-Contractor is engaged by ELITEZ (FMCG) PTE LTD to perform specific work duties, hereinafter known as "Contract Term" for ELITEZ (FMCG) PTE LTD's clients, hereinafter known as "Client".</p>
                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">Salary Terms for Sub-Contractor</p>
                    <p>2. ELITEZ (FMCG) PTE LTD shall pay the Sub-Contractor remuneration calculated at an agreed hourly rate for work performed during the Contract Term plus any additional monies from any incentive scheme.</p>
                    <p>3. All tax or statutory requirements will be borne and submitted to the authorities by the Sub-Contractor.</p>
                    <p>4. Salary will be disbursed one month or earlier upon approval of the timesheet or attendance or work done by the Client.</p>
                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">Confidentiality Agreement</p>
                    <p>5. The Sub-Contractor may not disclose to any persons the secrets and confidential information of ELITEZ (FMCG) PTE LTD or its Client(s). This obligation to maintain confidentiality of ELITEZ (FMCG) PTE LTD and its Client(s) continues even after cessation of employment. Legal action may be taken by ELITEZ (FMCG) PTE LTD against the said Sub-Contractor in breach of this agreement.</p>
                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">Sub-Contractor Obligations and Rights</p>
                    <p>6. Sub-Contractor must cooperate with Client and its assigned supervisors and accept the direct supervision and instruction of any authority in the Client's organization with respect to the service the Sub-Contractor provides during the Contract Term.</p>
                    <p>7. Sub-Contractor can refuse to do that work if he or she deems to be unreasonable which is out of the general job scopes being advertised and due to safety reasons.</p>
                    <p>8. ELITEZ (FMCG) PTE LTD will launch an investigation if Point 7 event occur and reserve the rights to ban the Client or Sub Contactor from the platform depending on the investigation report.</p>
                    <p class="text-subtitle-1 font-weight-medium">CONDUCT</p>
                    <p>9. Sub-Contractor must cooperate with ELITEZ (FMCG) PTE LTD or Client and its assigned supervisors, and accept the direct supervision and instruction of any authority in the Client’s organization with respect to the service the Sub-Contractor provides during the Contract Term within reasonable terms. The Sub-Contractor also agrees to the below guidelines during the Contract Terms. </p>
                    <p class="text-additional-point">1. No personal use of handphone, earphone, headset & earpiece accessory at work premise during working hours. A <strong>penalty of $50 SGD</strong> per offense might be imposed if violated.</p>
                    <p class="text-additional-point">2. No smoking is allowed during working hours</p>
                    <p class="text-additional-point">3. Maintain a good working relationship with the people around the workplace (Customers, Co-workers in store etc)</p>
                    <p class="text-additional-point">4. Proactive and productive during working hours</p>
                    <p class="text-additional-point">5. No Chit-chatting with other staffs when there are customers around</p>
                    <p>10. If the Sub-Contractor is unable to work for any reason on the Contract Term or report to work during the Contract Term, he/she must inform ELITEZ (FMCG) PTE LTD as soon as possible, so that alternative arrangements can be made. If the Sub-Contractor fails to do this and is unable to provide an equitable reason, a penalty of <strong class="text-decoration-underline">$50 SGD</strong> will be levied. ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out.</p>
                    <p>11. he Sub-Contractor responsibility is to ensure that they make necessary arrangements to be punctual for work. If the Sub-Contractor fails to report to work on time without a valid reason, a penalty of <strong>$10 SGD for every 10 minutes</strong> late might be imposed. ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out. </p>
                    <p>12. The Sub-Contractor is responsible for entering information pertaining to the deployment into the Blink Application. The Sub-Contractor will be given <strong>120 minutes</strong> after checking out of the shift to fill in the necessary information (Sales, Outlet Comments) to deem the shift as COMPLETED. Sub-Contractors who failed to complete the shift might have a penalty of <strong>$10 SGD</strong> imposed. ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out.</p>
                    <p>13. Sub-Contractor must not engage in any actions detrimental to the interests of ELITEZ (FMCG) PTE LTD and its Client. A penalty of <strong>$100 SGD</strong> will be imposed if violated. ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out.</p>
                    <p>14. Sub-Contractor must abide by the Personal Data Protection Act 2012. He/She is not allowed to extract or copy personal information or contact number except when allowed under specific work requirements. A penalty of <strong>$100 SGD</strong> per infringement will be imposed if it is violated.ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out.</p>
                    <p>15. Sub-Contractor must not leave their post for more than 15 minutes without any valid reasons. A penalty of <strong>$20 SGD</strong> per 15 minutes block will be imposed. ELITEZ (FMCG) PTE LTD is able to levy it on whatever remuneration that had not been paid out.</p>
                    <p>16. Sub-Contractor must take all reasonable steps to ensure his/her own safety during the Contract Term, and comply with the health and safety policies of ELITEZ (FMCG) PTE LTD and its Client.. Sub-Contractor engaged by ELITEZ (FMCG) PTE LTD are under the direction and control of the ELITEZ (FMCG) PTE LTD from the time the Sub-Contractor reports to take up duties and for the duration of the assignment.</p>
                    <p>17. The Sub-contractor agree to pay a finder’s fee of $1500 to ELITEZ (FMCG) PTE LTD should he/she be employed by the Client within six months from the day when ELITEZ (FMCG) last workday by the Sub-contractor in the work in the Client premise.</p>
                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">Service Engagement</p>
                    <p>18. This Agreement shall be governed with accordance to the laws of Singapore.</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-checkbox label="I have read and agreed to the terms and conditions" v-model="object.terms"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                    <v-btn rounded large class="elevation-0" :ripple="false" @click.prevent.stop="previous">Previous</v-btn>
                    <v-btn rounded large color="primary" class="elevation-0" :ripple="false" :disabled="!object.terms" @click.prevent.stop="next">Next</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>

export default {
    name: 'RegistrationAddressForm',
    props: {
        districts: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            object: {
                terms: false
            }
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        reset: function() {
            this.object.terms = false
        },
        previous: function() {
            this.$emit('previous')
        },
        next: function() {
            window.scrollTo(0, 0)
            this.$emit('next')
        }
    }
}

</script>


<style scoped>

/deep/ .v-btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

.text-additional-point {
    margin-left: 20px;
}

</style>