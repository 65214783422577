<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part I: Profile Info</h2>
        <v-form lazy-validation ref="form" class="mb-2" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="First Name (Same as NRIC)*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Last Name (Same as NRIC)*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Alias Name" :rules="rules.alias_name" :error-messages="errors.alias_name" v-model="object.alias_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC/FIN No. (Same as NRIC/FIN)*" :rules="rules.nric" :error-messages="errors.nric" v-model="object.nric"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Email" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Gender*" :items="genders" :rules="rules.gender" :menu-props="{top: false, offsetY: true}" :error-messages="errors.gender" v-model="object.gender"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Race*" :items="races" :menu-props="{top: false, offsetY: true}" :rules="rules.race" :error-messages="errors.race" v-model="object.race"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-dialog persistent ref="dobDialog" transition="scale-transition" :return-value.sync="object.dob" v-model="dobDialog" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable readonly label="Date of Birth*" v-bind="attrs" v-on="on" :rules="rules.dob" :error-messages="errors.dob" v-model="object.dob"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" :min="getMinDOBDate()" :max="getMaxDOBDate()" :active-picker.sync="activeDOBPicker" @change="$refs.dobDialog.save(object.dob)" v-model="object.dob"></v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.mobile_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Citizenship*" :items="citizenships" :menu-props="{top: false, offsetY: true}" :rules="rules.citizenship" :error-messages="errors.citizenship" v-model="object.citizenship"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="isPR()">
                    <v-dialog persistent ref="doprDialog" transition="scale-transition" :return-value.sync="object.dopr" v-model="doprDialog" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable readonly label="Date of PR*" v-bind="attrs" v-on="on" :rules="rules.dopr" :error-messages="errors.dopr" v-model="object.dopr"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" :active-picker.sync="activeDOPRPicker" :max="getMaxDOPRDate()" @change="$refs.doprDialog.save(object.dopr)" v-model="object.dopr"></v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-autocomplete dense clearable label="Shirt Size*" :items="shirtSizes" :menu-props="{top: false, offsetY: true}" :rules="rules.shirt_size" :error-messages="errors.shirt_size" v-model="object.shirt_size"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">* Required field</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-right">
                    <v-spacer></v-spacer>
                    <v-btn block large rounded color="primary" class="btn elevation-0" :ripple="false" :loading="loading" @click.prevent.stop="save">Next</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>

import { makeDate, today } from '@/utils/dates'
import { copyObject } from '@/utils/helper'
import { encodeBase64 } from '@/utils/encodes'

export default {
    name: 'RegistrationPersonalForm',
    props: {
        races: {
            type: Array,
            required: false,
            default: () => []
        },
        genders: {
            type: Array,
            required: false,
            default: () => []
        },
        citizenships: {
            type: Array,
            required: false,
            default: () => []
        },
        shirtSizes: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            valid: true,
            dobDialog: false,
            doprDialog: false,
			activeDOBPicker: null,
			activeDOPRPicker: null,
            object: {
                first_name: '',
				last_name: '',
				alias_name: '',
				nric: '',
				email: '',
				mobile_number: '',
				race: '',
				gender: '',
				dob: "",
				dopr: '',
				citizenship: '',
				shirt_size: '',
            },
            rules: {
                first_name: [
					(value) => !!value || 'First name is required',
					(value) => value && value.length >= 2 && value.length <= 100 || 'First name must be between 2 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value && value.length >= 2 && value.length <= 100 || 'Last name must be between 2 and 100 characters'
				],
				alias_name: [
                    (value) => {
                        if(value && value.length < 2 && value.length > 100) {
                            return 'Alias name must be between 2 and 100 characters'
                        }

                        return true
                    }
				],
				nric: [
                    (value) => !!value || 'NRIC is required',
					(value) => value && value.length == 9 || 'NRIC must be 9 characters'
				],
				email: [
					(value) => {
                        if(value && !/.+@.+\..+/.test(value)){
                            return 'Email is invalid'
                        }

                        return true
                    }
				],
				gender: [
					(value) => !!value || 'Gender is required'
				],
				race: [
					(value) => !!value || 'Race is required'
				],
				citizenship: [
					(value) => !!value || 'Citizenship is required'
				],
				dob: [
					(value) => !!value || 'Date of birth is required'
				],
				mobile_number: [
                    (value) => {
                        if(!value) {
                            return 'Mobile number is required'
                        }

                        if(value.length !== 8) {
                            return 'Mobile number must be 8 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 8 digits'
                        }

                        if(!['8', '9'].includes(value.substring(0, 1))) {
                            return 'Mobile number must starts with 8 or 9'
                        }

                        return true
					}
				],
				dopr: [
                    (value) => {
                        const citizenship = this.object.citizenship
                        if(!value && citizenship && citizenship.toLowerCase() === 'pr'){
                            return "Date of PR is required"
                        }

                        return true
                    }
				],
                shirt_size: [
                    (value) => !!value || "Shift size is required"
                ]
            },
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        getMinDOBDate: function() {
            return makeDate(new Date().getFullYear() - 80, 1, 1)
        },
        getMaxDOBDate: function() {
            return makeDate(new Date().getFullYear() - 16, 12, 31)
        },
        getMaxDOPRDate: function() {
            return today()
        },
        save: function() {
            if(this.form.validate()){
				this.loading = true
				var object = { 'nric': encodeBase64(this.object.nric), 'citizenship': this.object.citizenship,
                               'dopr': this.object.dopr }

                this.$store.dispatch('mobile/account/checkAccount', object).then(() => {
                    this.$emit('next', this.cleanObject(copyObject(this.object)))
                    this.loading = false
                    this.errors = {}
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
        },
        reset: function() {
            this.loading = false
            this.errors = {}
            this.object = { first_name: '', last_name: '', alias_name: '', nric: '', email: '', mobile_number: '',
                            dob: '', dopr: '', race: '', gender: '', citizenship: '', shirt_size: '' }
            this.form.resetValidation()
        },
        isPR: function() {
            return this.object.citizenship === 'PR'
        },
        cleanObject: function(item) {
            var race = this.races.filter((i) => i.value === item.race)
            var gender = this.genders.filter((i) => i.value === item.gender)
            var citizenship = this.citizenships.filter((i) => i.value === item.citizenship)
            var shirtSize = this.shirtSizes.filter((i) => i.value === item.shirt_size)
            item.race = race.length > 0?{'text': race[0].text, 'value': race[0].value }:{'text': 'N.A.', 'value': item.race }
            item.gender = gender.length > 0?{'text': gender[0].text, 'value': gender[0].value }:{'text': 'N.A.', 'value': item.gender }
            item.citizenship = citizenship.length > 0?{'text': citizenship[0].text, 'value': citizenship[0].value }:{'text': 'N.A.', 'value': item.citizenship }
            item.shirt_size = shirtSize.length > 0?{'text': shirtSize[0].text, 'value': shirtSize[0].value }:{'text': 'N.A.', 'value': item.shirt_size }
            return item
        }
    },
    watch: {
        dobDialog (value) {
            value && setTimeout(() => (this.activeDOBPicker = 'YEAR'))
        },
        doprDialog (value) {
            value && setTimeout(() => (this.activeDOPRPicker = 'YEAR'))
        }
    }
}

</script>


<style scoped>

.btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

</style>