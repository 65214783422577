<template>
	<v-app>
		<v-main>
            <v-container fluid>
                <v-app-bar app class="text-h5 text-center white--text elevation-0" color="primary">FMCG Application Form</v-app-bar>
                <v-stepper v-model="stepper" class="elevation-0">
                    <v-stepper-items>
                        <v-stepper-content step="0" class="px-1 pt-2">
                            <welcome-form ref="welcomeForm" @updated="nextStep"></welcome-form>
                        </v-stepper-content>
                        <v-stepper-content step="1" class="px-1 pt-2">
                            <profile-form ref="profileForm" :races="races" :genders="genders" :citizenships="citizenships" :shirt-sizes="shirt_sizes" @next="updateProfileInfo"></profile-form>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="px-1 pt-2">
                            <address-form ref="addressForm" :districts="districts" @previous="previousStep" @next="updateAddressInfo"></address-form>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="px-1 pt-2">
                            <bank-form ref="bankForm" :banks="banks" @previous="previousStep" @next="updateBankInfo"></bank-form>
                        </v-stepper-content>
                        <v-stepper-content step="4" class="px-1 pt-2">
                            <terms-conditions-form ref="termsForm" @previous="previousStep" @next="nextStep"></terms-conditions-form>
                        </v-stepper-content>
                        <v-stepper-content step="5" class="px-1 pt-2">
                            <data-policy-form ref="dataForm" @previous="previousStep" @next="nextStep"></data-policy-form>
                        </v-stepper-content>
                        <v-stepper-content step="6" class="px-1 pt-2">
                            <review-form ref="reviewForm" @previous="previousStep" @next="handleReviewForm"></review-form>
                        </v-stepper-content>
                        <v-stepper-content step="7" class="px-1 pt-2">
                            <completed-form ref="completedForm" @next="nextStep"></completed-form>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
			</v-container>
		</v-main>
	</v-app>
</template>


<script>

import RegistrationWelcomeForm from './RegistrationWelcomeForm.vue'
import RegistrationProfileForm from './RegistrationProfileForm.vue'
import RegistrationAddressForm from './RegistrationAddressForm.vue'
import RegistrationBankForm from './RegistrationBankForm.vue'
import RegistrationTermsConditionsForm from './RegistrationTermsConditionsForm.vue'
import RegistrationDataPolicyForm from './RegistrationDataPolicyForm.vue'
import RegistrationReviewForm from './RegistrationReviewForm.vue'
import RegistrationCompletedForm from './RegistrationCompletedForm.vue'

export default {
	name: 'RegistrationForm',
	components: {
        welcomeForm: RegistrationWelcomeForm,
        profileForm: RegistrationProfileForm,
        addressForm: RegistrationAddressForm,
        bankForm: RegistrationBankForm,
        termsConditionsForm: RegistrationTermsConditionsForm,
        dataPolicyForm: RegistrationDataPolicyForm,
        reviewForm: RegistrationReviewForm,
        completedForm: RegistrationCompletedForm,
	},
	data() {
		return {
            stepper: 0,
            object: {
				first_name: '',
				last_name: '',
				alias_name: '',
				nric: '',
				email: '',
				gender: '',
				dob: '',
				race: '',
				mobile_number: '',
				citizenship: '',
				dopr: '',
				shirt_size: '',
				cpf_choice: '',
				bank: '',
				account_number: '',
				block_number: '',
				street_name: '',
				unit_number: '',
				postal_code: '',
				district: '',
			},
			races: [],
			genders: [],
			districts: [],
			citizenships: [],
			shirt_sizes: [],
			banks: []
		}
	},
	computed: {
        welcomeForm() {
            return this.$refs.welcomeForm
        },
		profileForm() {
			return this.$refs.profileForm
		},
		addressForm() {
            return this.$refs.addressForm
		},
		bankForm() {
            return this.$refs.bankForm
		},
        termsForm() {
            return this.$refs.termsForm
        },
        dataForm() {
            return this.$refs.dataForm
        },
        reviewForm() {
            return this.$refs.reviewForm
        },
        completedForm() {
            return this.$refs.completedForm
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
            this.$store.dispatch('mobile/account/getAccount').then((response) => {
				this.citizenships = response.data.citizenships
				this.races = response.data.races
				this.genders = response.data.genders
				this.districts = response.data.districts
				this.banks = response.data.banks
				this.shirt_sizes = response.data.shirt_sizes
			}).catch(() => {
				this.loading = false;
			})
		},
        previousStep: function() {
            if(this.stepper > 1){
                this.stepper -= 1
            }
        },
        nextStep: function() {
            if(this.stepper >= 7){
                this.stepper = 0
            }else{
                this.stepper += 1
            }
        },
        updateProfileInfo: function(item) {
            this.$set(this, 'object', item)
            this.nextStep()
        },
        updateAddressInfo: function(item) {
            this.$set(this.object, 'district', item.district)
            this.$set(this.object, 'block_number', item.block_number)
            this.$set(this.object, 'street_name', item.street_name)
            this.$set(this.object, 'unit_number', item.unit_number)
            this.$set(this.object, 'building_name', item.building_name)
            this.$set(this.object, 'postal_code', item.postal_code)
            this.nextStep()
        },
        updateBankInfo: function(item) {
            this.$set(this.object, 'bank', item.bank)
            this.$set(this.object, 'account_number', item.account_number)
            this.reviewForm.updateObject(this.object)
            this.nextStep()
        },
        handleReviewForm: function() {
            this.profileForm.reset()
            this.addressForm.reset()
            this.bankForm.reset()
            this.termsForm.reset()
            this.dataForm.reset()
            this.nextStep()
        }

	}
}

</script>


<style scoped>

/deep/ .v-toolbar__content {
    justify-content: center !important;
}

</style>