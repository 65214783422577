<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part III: Bank Info</h2>
        <v-form lazy-validation ref="form" class="mb-2" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select dense clearable label="Bank*" :items="banks" :menu-props="{top: false, offsetY: true}" :rules="rules.bank" :error-messages="errors.bank" v-model="object.bank"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Account No.*" :rules="rules.account_number" :error-messages="errors.account_number" v-model="object.account_number"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">* Required field</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                    <v-btn rounded large class="elevation-0" :ripple="false" @click.prevent.stop="previous">Previous</v-btn>
                    <v-btn rounded large class="elevation-0" :ripple="false" color="primary" @click.prevent.stop="next">Next</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
    name: 'RegistrationBankForm',
    props: {
        banks: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            object: {
                bank: '',
                account_number: ''
            },
            rules: {
                bank: [
					(value) => !!value || 'Bank is required'
				],
                account_number: [
                    (value) => {
                        if(!value) {
                            return 'Account number is required'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Account number must be numeric value'
                        }

                        const banks = this.banks.filter(item => item.value === this.object.bank)

                        if(banks.length > 0){
                            const bank = banks[0]
                            if(bank.account_digits.length > 0 && !bank.account_digits.includes(value.length.toString())){
                                return 'Account length must be ' + bank.account_digits.join(' or ')
                            }
                        }

                        return true
                    }
                ],
            },
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        reset: function() {
            this.object = { 'bank': '', 'account_number': '' }
            this.errors = {}
            this.form.reset()
            this.form.resetValidation()
        },
        previous: function() {
            this.$emit('previous')
        },
        next: function() {
            if(this.form.validate()){
                var object = copyObject(this.object)
                var bank = this.banks.filter((i) => i.value === object.bank)
                object.bank = bank.length > 0?{'text': bank[0].text, 'value': bank[0].value }:{'text': 'N.A.', 'value': object.bank }
				this.$emit('next', object)
            }
        }
    }
}

</script>


<style scoped>

/deep/ .v-btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

</style>