<template>
    <div>
        <h2 class="text-h6 text-sm-h6 mb-3">Part V: Data Protection Notice For Customers</h2>
        <v-form lazy-validation ref="form" class="mb-2" v-model="valid">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-subtitle-2 text-justify font-weight-regular">
                    <p>Last Updated: 14th September 2019</p>
                    <p>This Data Protection Notice "<span class="font-weight-bold">Notice</span>" sets out the basis which <span class="font-italic">Elitez FMCG Pte Ltd</span> ("<span class="font-weight-bold">we</span>", "<span class="font-weight-bold">us</span>" or "<span class="font-weight-bold">our</span>") may collect, use, disclose or otherwise process personal data of our customers in accordance with the Personal Data Protection Act ("<span class="font-weight-bold">PDPA</span>"). This Notice applies to personal data in our possession or under our control, including personal data in the possession of organisations which we have engaged to collect, use, disclose or process personal data for our purposes.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">PERSONAL DATA</p>
                    <p>1. As used in this Notice:<br>
                    "<strong>customer</strong>" means an individual who <br>
                    (a) has contacted us through any means to find out more about any goods or services we provide, or <br>
                    (b) may, or has, entered into a contract with us for the supply of any goods or services by us; and<br><br>
                    "<strong>personal data</strong>" means data, whether true or not, about a customer who can be identified:<br>
                    (a) from that data; or <br>
                    (b) from that data and other information to which we have or are likely to have access.
                    </p>
                    <p>2. Depending on the nature of your interaction with us, some examples of personal data which we may collect from you include name, residential address, email address, telephone number, nationality, gender, date of birth and photograph.</p>
                    <p>3. Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so permits).</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA</p>
                    <p>4. We generally do not collect your personal data unless<br>
                      (a) it is provided to us voluntarily by you directly or via a third party who has been duly authorised by you to disclose your personal data to us (your "<strong>authorised representative</strong>") after (i) you (or your authorised representative) have been notified of the purposes for which the data is collected, and (ii) you (or your authorised representative) have provided written consent to the collection and usage of your personal data for those purposes, or<br>
                      (b) collection and use of personal data without consent is permitted or required by the PDPA or other laws. We shall seek your consent before collecting any additional personal data and before using your personal data for a purpose which has not been notified to you (except where permitted or authorised by law).
                    </p>
                    <p>5. We may collect and use your personal data for any or all of the following purposes: <br>
                    (a) performing obligations in the course of or in connection with our provision of the goods and/or services requested by you;<br>
                    (b) verifying your identity;<br>
                    (c) responding to, handling, and processing queries, <br>requests applications, complaints, and feedback from you;<br>
                    (d) managing your relationship with us;<br>
                    (e) complying with any applicable laws, regulations, codes of practice, guideline, or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;<br>
                    (f) any other purposes for which you have provided the information;<br>
                    (g) transmitting to any unaffiliated third parties including our third party service providers and agents, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes; and<br>
                    (h) any other incidental business purposes related to or in connection with the above.
                    </p>
                    <p>6. We may disclose your personal data:<br>
                      (a) where such disclosure is required for performing obligations in the course of or in connection with our provision of the goods or services requested by you; or<br>
                      (b) to third party service providers, agents and other organisations we have engaged to perform any of the functions with reference to the above.
                    </p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">DEEMED CONSENT BY NOTIFICATION</p>
                    <p>7. We may collect or use your personal data, or disclose existing personal data for secondary purposes that differ from the primary purpose which it had orginally collected for pursuant to clauses 5 and 6. If <span class="font-italic">Elitez FMCG Pte Ltd</span> intends to rely on deemed consent by notification for such secondary purposes, <span class="font-italic">Elitez FMCG Pte Ltd</span> will notify you of the proposed collection, use or disclosure of his personal data through appropriate mode(s) of communication.</p>
                    <p>8. In particular, we may rely on deemed consent by notification to collect, use or disclose your personal data for the following purposes:<br>
                        i. Collection of NRIC particulars for verification of legal agefor certain samples
                    </p>
                    <p>9. Before relying on deemed consent by notification, <span class="font-italic">Elitez FMCG Pte Ltd</span> will assess and determine that the collection, use and disclosure of the personal data will not likely have an adverse effect on you.</p>
                    <p>10. You will be given a reasonable period to inform us if you wish to opt-out of the collection, use and disclosure of your personal data for such purposes.</p>
                    <p>11. After the lapse of the opt-out period, you may notify us that you no longer wish to consent to the purposes for which your consent was deemed by notification by withdrawing your consent for the collection, use or disclosure of your personal data in relation to those purposes.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium text-left">RELIANCE ON THE LEGITIMATE INTERESTS EXCEPTION</p>
                    <p>12. In compliance with the PDPA, we may collect, use or disclose your personal data without your consent for the legitimate interests of <span class="font-italic">Elitez FMCG Pte Ltd</span> or another person. In relying on the legitimate interests exception of the PDPA, <span class="font-italic">Elitez FMCG Pte Ltd</span> will assess the likely adverse effects on the individual and determine that the legitimate interests outweight any adverse effect.</p>
                    <p>13. In line with the legitimate interests' exception, we will collect, use or disclose your personal data for the following purposes:<br>
                    a. Fraud detection and prevention; and<br>
                    b. Detection and prevention of misuse of services.<br><br>
                    The purposes listed in the above clause may continue to apply even in situations where your relationship with us (for example, pursuant to a contract) has been terminated or altered in any way, for a reasonable period thereafter.
                    </p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">WITHDRAWING YOUR CONSENT</p>
                    <p>14. The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop using and/or disclosing your personal data for any or all of the purposes listed above by submitting your request in writing or via email to our Data Protection Officer at the contact details provided below.</p>
                    <p>15. Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and liabilities to us. In general, we shall seek to process your request within twenty (20) business days of receiving it. </p>
                    <p>16. Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of your request, we may not be in a position to continue providing our goods or services to you and we shall, in such circumstances, notify you before completing the processing of your request. Should you decide to cancel your withdrawal of consent, please inform us in writing in the manner described in clause 7 above.</p>
                    <p>17. Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal data where such collection, use and disclose without consent is permitted or required under applicable laws.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">ACCESS TO AND CORRECTION OF PERSONAL DATA</p>
                    <p>18. If you wish to make<br>
                    (a) an access request for access to a copy of the personal data which we hold about you or information about the ways in which we use or disclose your personal data, or <br>
                    (b) a correction request to correct or update any of your personal data which we hold about you, you may submit your request in writing or via email to our Data Protection Officer at the contact details provided below.</p>
                    <p>19. Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the fee before processing your request.</p>
                    <p>20. We will respond to your request as soon as reasonably possible. Should we not be able to respond to your request within twenty (20) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">PROTECTION OF PERSONAL DATA</p>
                    <p>21. To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks, we have introduced appropriate administrative, physical and technical measures such as minimised collection of personal data, authentication and access controls (such as good password practices, need-to-basis for data disclosure, etc.), data anonymisation, up-to-date antivirus protection, regular patching of operating system and other software, and securely erase storage media in devices before disposal.</p>
                    <p>22. You should be aware, however, that no method of transmission over the Internet or method of electronic storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your information and are constantly reviewing and enhancing our information security measures.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">ACCURACY OF PERSONAL DATA</p>
                    <p>23. We generally rely on personal data provided by you (or your authorised representative). In order to ensure that your personal data is current, complete and accurate, please update us if there are changes to your personal data by informing our Data Protection Officer in writing or via email at the contact details provided below.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">RETENTION OF PERSONAL DATA</p>
                    <p>24. We may retain your personal data or as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws.</p>
                    <p>25. We will cease to retain your personal data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected, and is no longer necessary for legal or business purposes.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE</p>
                    <p>26. We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we will obtain your consent for the transfer to be made and we will take steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">DATA PROTECTION OFFICER</p>
                    <p>27. You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data protection policies and procedures, or if you wish to make any request, in the following manner:<br>
                        Name of DPO:&nbsp;&nbsp;Eugene Chua <br>
                        Contact No.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;96582857 <br>
                        Email Address:&nbsp;eugene.chua@elitez.asia<br>
                    </p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">EFFECT OF NOTICE AND CHANGES TO NOTICE</p>
                    <p>28. This Notice applies in conjunction with any other notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your personal data by us.</p>
                    <p>29.We may revise this Notice from time to time without any prior notice. You may determine if any such revision has taken place by referring to the date on which this Notice was last updated. Your continued use of our services constitutes your acknowledgement and acceptance of such changes, in part, without written permission.</p>


                    <p class="text-subtitle-1 text-decoration-underline font-weight-medium">ACKNOWLEDGEMENT AND CONSENT</p>
                    <p><v-checkbox label="I acknowledge that I have read and understood the PDPA Notice, and consent to the collection, use and disclosure of my personal data by Elitez Group of Companies for the purposes set out in the Notice." v-model="object.data_policy"></v-checkbox></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-space-between">
                    <v-btn rounded large class="elevation-0" :ripple="false" @click.prevent.stop="previous">Previous</v-btn>
                    <v-btn rounded large color="primary" class="elevation-0" :ripple="false" :disabled="!object.data_policy" @click.prevent.stop="next">Next</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>


<script>

export default {
    name: 'RegistrationAddressForm',
    props: {
        districts: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            object: {
                data_policy: false,
            }
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        reset: function() {
            this.object = { data_policy: false }
        },
        previous: function() {
            this.$emit('previous')
        },
        next: function() {
            window.scrollTo(0, 0)
            this.$emit('next')
        }
    }
}

</script>


<style scoped>

/deep/ .v-btn {
    min-width: 120px !important;
    max-width: 120px !important;
}

</style>